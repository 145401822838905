@import '../../scss/colors';
@import '../../scss/hiv';

#view-notes-button {
  background-image: url('./notes-icon.svg');
  background-size: cover;
  width: 27px;
  height: 30px;
  padding: 0;

  @include medium { 
    height: 40px;
    width: 36px;
  }
}

.dialog.note-dialog {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  height: 60vh;
  min-height: 350px;
  @include medium { min-height: 520px; }

  div, p {
    font-style: italic;
    font-size: 1rem;
  }

  .close-x { display: none; }

  .note-dialog-intro .markdown { flex-direction: column; }

  .note-dialog-textarea {
    -webkit-appearance: none;
    position: relative;
    flex: 1 0;

    
    textarea {
      background-color: transparent;
      z-index: 100;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 15px;
      border: 1px solid $dark_gray;
      border-radius: 6px;
      position: relative;
      font-size: 1rem;
      &::placeholder { color: transparent }
    }
    
    .placeholder {
      $placeholder-padding: 15px;
      padding: $placeholder-padding;
      position: absolute;
      height: 100%;

      div { 
        height: calc(100% - (2 * #{$placeholder-padding})); 
        overflow: hidden;

        p { color: $medium_gray; }
      }
    }
  }

  .note-dialog-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 15px;
    
    button { 
      @include nav-button-styling;
      height: 30px;
      width: 100px;
      @include medium { height: 40px; }
    }
   }
}