@import '../../../scss/base';

.bullet-list { 
  padding-left: 30px;

  @include medium { padding-left: 40px; }

  > li, > p {
    list-style-type: none;
    position: relative;  
    margin-bottom: 1rem;

    &:before {
      content: '\00BB';
      position: absolute;
      left: -22px;
      @include medium { left: -30px; }
      align-self: flex-start;
    }
  }
}

.dialog-body {
  p + .bullet-list { margin-top: 20px; }
}
