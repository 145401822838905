@import '../../scss/base';
@import '../../scss/colors';
@import '../../scss/hiv';

.welcome {
  display:flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  align-items: center;
  background-image: url('./hello-beautiful-background.jpg');
  background-size: cover;
  
  .welcome-content {
    $vertical-buffer: 20px;
    box-sizing: border-box;
    padding: $vertical-buffer 0;
    max-height: none;
    @include medium { max-height: 640px + ($vertical-buffer * 2); }
    max-width: 670px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    justify-content: center;
    
    h1 {
      color: white;
      text-align: center;
      font-weight: 1000;
      text-transform: none;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      margin: 0;
      font-size: 12.5vw;
      @include medium { font-size: 96px; }
      // color: purple;
    }

    .welcome-body {
      @include archer;
      text-align: center;
      
      margin: 40px 0;
      height: 150px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      line-height: 1.5;
      color: $dark_gray;
      font-size: 24px;
      white-space: pre-wrap;
      @include medium { 
        font-size: 32px;
        white-space: normal;
      }
      // color: purple;
    }

    .dialog-box {
      max-width: 300px;
      text-decoration: none;
      @include medium { margin-top: auto; }
      @include dialog-box-styling;
    }
  }
}