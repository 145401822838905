@import '../../scss/hiv';

.why {
  .bullet-list li:before { 
    color: $dark_blue;
    
  }
  
  .dialog-box { 
    @include dialog-box-styling;
    max-width: 400px;
    align-self: center;
    margin-bottom: 30px;
    word-wrap:normal;
  }
}

.overlay .dialog.why-dialog { 
  .dialog-body { 
    // padding: 0 15px 10px;
    // @include medium { padding: 0 30px 10px; }
    @include bullet-list-colors;

    .bullet-list {
      padding-left: 22px;

      li:before { left: -22px; }
    }
  }
}