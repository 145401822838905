@import '../../scss/base';
@import '../../scss/colors';
@import '../../scss/hiv';

$numOfIcons: 5;
$iconWidth: 125px;

.useIt { 
  background-color: white;

  .tabs {
    @include tab-title-bar;
    margin-bottom: 30px;
  }

  .tabs-icon-bar {
    flex-direction: column;
    @include medium { flex-direction: row; }

    .tab-icon-container {
      display: flex;
      align-items: center;
      padding: 9px 15px;

      @include medium { padding: 0; }

      p { 
        margin: 0 0 0 15px;
        color: white;
        @include archer;
      }

      .tab-icon {
        width: 60px;
        z-index: 50;
        border-radius: 50%;
        border: 1px solid white;

        @include medium { 
          width: $iconWidth;
          border-radius: 0;
          z-index: 0;
          border: none;
        }
      }

      .down-arrow {
        width: 15px;
        margin-left: auto;
      }
    }
  }

  .selected-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    
    @include medium { 
      width: $iconWidth * $numOfIcons;
      padding: 15px 0;
    }
    
    .dialog-box {
      @include dialog-box-styling;
      max-width: 400px;
    }
    
    .icon-bullet-list { 
      margin: 5px 0 20px 0;
      padding-left: 0;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;

        @include medium { align-items: center; }
      }

      img { align-self: initial; }

      div {
        display: flex;
        @include medium { align-items: center; }
        p { margin: 0; }
      }

      li, p {
        list-style-type: none;
        position: relative;  
        font-size: 1rem;
        @include medium { font-size: 1.125rem; }

        img { 
          margin-right: 20px;
          width: 50px;

          @include medium { width: 60px; }
        }
      }
    }
  }
}

.dialog {
  @include bullet-list-colors;

  .dialog-box { 
    @include dialog-box-styling;
    justify-content: flex-start;
    margin: 0;
    height: 90px;
    border-top-left-radius: 6px;
  }

  .dialog-body {
    p { 
      margin-bottom: 15px; 
      @include medium { margin-bottom: 20px; }
    }
  }
}
