@import '../../scss/colors';

.ImageKanban {
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    max-width: 688px;
    width: 100%;
    box-sizing: border-box;
  }

  header {
    background: $dark_gray;
    padding: 0 20px;

    h1 {
      color: white;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 1rem;
    }
  }

  .markdown {
    p, span {font-size: 10px; }

    p { margin: 2px 0; }

    span {
      &.orange { color: #F98200; }
      &.salmon { color: #E04628; }
      &.blood { color: #A90C02; }
      &.blue { color: $light_blue; }
    }

    a {
      text-decoration: none;
      color: $medium_blue;
    }
  }
}
