@import '../../scss/hiv';

.hiv {
  .dialog-box { 
    @include dialog-box-styling;
    max-width: 400px;
    align-self: center;
    margin: 12px 0 30px;
    height: 70px;
  } 
}

.dialog .ImageKanban {
  * { max-width: 400px; }

  .markdown {
    margin-top: 4px;

    p, span { font-size: 10px; }
  }
}
