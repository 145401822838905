@import "../../scss/base";
@import "../../scss/colors";
@import "../../scss/hiv";

.header-image {
  width: 100%;
  object-fit: cover;
  height: 140px;
  @include medium { height: 180px; }

  &.center { object-position: 50% 0; }
  &.left { object-position: 0 0; }
  &.center-left {
    object-position: 15% 0;
  }
}

main {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 0 5vw;

  @include large { padding: 0 64px;  }
}

footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 40px;

  @include medium { margin-bottom: 30px;  }

  a {
    @include nav-button-styling;
  }
}
