.secret-dev-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    background-color: green;
    color: #fff;
    font-size: 0.9375rem;
    font-style: italic;
    height: 30px;
    line-height: 1.8;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    width: 200px;
    margin: 5px 0;
  }
}