.icon-bullet-list { 
  li {
    display: flex;

    p { flex: 1 }

    > * { align-self: stretch; }

    > img { align-self: flex-start; }
  }
}