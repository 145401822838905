@import '../../scss/base';
@import '../../scss/colors';

h1 {  
  @include archer-heading;
  color: $dark_blue;
}

p { 
  margin-top: 0;
}

p, li, button {
  color: $dark_gray;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1rem;
  line-height: 1.35;

  @include medium {
    font-size: 1.25rem;
  }
}

a, textarea, div { 
  font-family: Georgia, 'Times New Roman', Times, serif;
}
