.nav {
  background-color: cornsilk;
  list-style: none;
  padding: 0;

  a {
    display: block;
    text-decoration: none;
  }

  .nav-item {
    background-color: azure;
    margin-bottom: 10px;

    &.visited {
      background-color: cornflowerblue;
    }
  }
}