@import '../../scss/colors';
@import '../../scss/base';

.talk-to-provider {
  .bullet-list {
    
    li:before { 
      color: $medium_blue;
    }

    li ul {
      list-style-type: none; 

      li { 
        position: relative;
        margin-bottom: 1rem;

        @include medium { margin-bottom: 1.25rem; }

        &:before {
          content: '-';
          position: absolute;
          left: -22px;
          align-self: flex-start;
        }
      }
    }
  }
  
  #view-only-notes {
    border-radius: 6px;
    white-space: pre;
    border: 1px solid $dark_gray;
    background-color: $white_gray;
    flex-grow: 1;
    margin-bottom: 30px;
    padding: 20px;
    min-height: 200px;
    box-sizing: border-box;
    @include medium { min-height: 150px; }
  }
}