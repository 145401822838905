@import './colors';
@import './base';

@mixin mobile-hiv-tabs-colors {
  .tab-mobile-grouping { 
    &:nth-of-type(1) .tab-icon-container { background-color: $medium_blue }
    &:nth-of-type(2) .tab-icon-container { background-color: $light_blue }
    &:nth-of-type(3) .tab-icon-container { background-color: $light_green }
    &:nth-of-type(4) .tab-icon-container { background-color: $orange }
    &:nth-of-type(5) .tab-icon-container { background-color: $dark_orange }

    &:nth-of-type(1) .accordion-bottom { background-color: $medium_blue }
    &:nth-of-type(2) .accordion-bottom { background-color: $light_blue }
    &:nth-of-type(3) .accordion-bottom { background-color: $light_green }
    &:nth-of-type(4) .accordion-bottom { background-color: $orange }
    &:nth-of-type(5) .accordion-bottom { background-color: $dark_orange }
  }
}

@mixin bullet-list-colors {
  &.PrEP li:before { color: $medium_blue }
  &.PEP li:before { color: $light_blue }
  &.condoms li:before { color: $light_green }
  &.hivTesting li:before { color: $orange }
  &.treatment li:before { color: $dark_orange }
}

@mixin dialog-box-styling { 
  @include archer;
  
  background-image: url('../shared_images/hatched-button-overlay-tablet@2x.png');
  background-size: cover;
  width: 100%;
  box-sizing: border-box;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 22px;
  padding: 0 15px;
  color: white;
  text-transform: none;
  white-space: pre-wrap;

  @include medium { 
    white-space: normal;
    padding: 0 30px;
  }

  &.PrEP { background-color: $medium_blue }
  &.PEP { background-color: $light_blue }
  &.condoms { background-color: $light_green }
  &.hivTesting { background-color: $orange }
  &.treatment { background-color: $dark_orange }
}

@mixin nav-button-styling {
  background-color: $dark_blue;
  color: #fff;
  font-style: italic;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1.875;
  font-size: 1rem;
  width: 100px;

  @include medium {
    width: 130px;
    font-size: 1.25rem;
    line-height: 2;
  }
}

@mixin icon-bullet-list-styling {
  .icon-bullet-list {
    padding: 0;
    margin-bottom: 10px;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      @include medium { align-items: center; }
    }

    p { margin: 0; }

    h2 {
      @include archer;
      margin: 0 0 0.25rem;
      font-size: 1.125rem;
    }

    li, p {
      list-style-type: none;
      position: relative;  
      font-size: 1rem;

      img { 
        margin-right: 20px;
        width: 60px;
        border-radius: 50%;
        flex-shrink: 0;

        @include medium { width: 110px; }
      }
    }
  }
}

@mixin tab-title-bar {
  .title-bar {
    display: none;
    background: $dark_blue;
    width: 625px;
    color: white;
    padding: 6px 10px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;

    @include medium { display: block; }
  }
}
