@import '../../scss/base';
@import '../../scss/colors';

.nav {
  background: none;
  margin: 0 auto;
  max-width: 610px;  // override width when container is > 610px
  width: 100%;

  img {
    height: 16px;

    @include small { height: 20px }
    @include medium { height: 27px }
  }

  .nav-item {
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.5);
    display: flex;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 0.875rem;
    justify-content: space-between;
    padding: 14px 3%;

    .nav-label {
      @include archer;
      color: white;
      font-style: italic;
    }

    &.visited { 
      background-image: url('../../shared_images/hatched-button-overlay-tablet@2x.png');
      background-size: 150%;
      background-repeat: repeat;
    }

    @include small { font-size: 1.125rem; }
    @include medium {
      font-size: 1.5rem;
      padding: 17px 25px 17px 20px;
    }
  }

  :first-child .nav-item { background-color: $orange; }
  :nth-child(2) .nav-item { background-color: $dark_orange; }
  :nth-child(3) .nav-item { background-color: $light_green; }
  :nth-child(4) .nav-item { background-color: $dark_green; }
  :nth-child(5) .nav-item { background-color: $medium_blue; }
}
