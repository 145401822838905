@import '../../../scss/base';
@import '../../../scss/colors';
@import '../../../scss/hiv';

.tabs {
  flex-direction: column;
  align-items: center;

  &.mobile {
    width: 100vw;
    position: relative;
    left: -5vw;

    .selected-content-mobile-grouping { 
      &.slide-up, &.slide-down {
        max-height: 0;            
        overflow-y: hidden;
        -webkit-transition: max-height 1s ease-in-out;
        -moz-transition: max-height 1s ease-in-out;
        -o-transition: max-height 1s ease-in-out;
        transition: max-height 1s ease-in-out;
      }
  
      &.slide-down { max-height: 100vh; }
    }
  }

  &.tablet { 
    width: 100%;
   }

  .tabs-icon-bar { 
    display: flex;
    justify-content: center;
    width: 100%;
    @include mobile-hiv-tabs-colors;

    .accordion-bottom {
      height: 10px;
      width: 100%;
      background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
      background-size: cover;
    }

    .tab-icon-container { 
      width: 100%;
      @include medium { width: auto; }
     }

    .tab-icon-container.selected {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
        position: absolute;
        left: 0;
        background-size: cover;
      }
    }
   }
}