@import '../../scss/hiv';

.outro {
  padding-top: 5vh;

  .dialog-box {
    @include dialog-box-styling;
    text-decoration: none;
    max-width: 400px;
    align-self: center;
    margin-bottom: 30px;
    word-wrap:normal; 

    &.dark-blue { background-color: $dark_blue }
  }

  .outro-nav-group {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
