@import '../../scss/base';

.ImageBanner {
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  @include medium { padding: 0 }

  .text-container {
    padding-left: 22px;

    span {
      @include archer;
      font-weight: 800;
      font-size: 1.5rem;
      line-height: 1.3;
      @include medium { font-size: 2rem; }

      &.orange { color: #F37928; }
      &.green { color: #A2C800; }
      &.blue { color: #006477; }
    }
  }
}
