@import-normalize;
@import 'scss/base';

ul { margin: 0 };

button { border: none; }

// debug
// *:focus { outline: 3px solid purple !important; }

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow-y: auto;

  &.ReactModal__Body--open {
    // [using this to prevent modal scrolling outside of the overlay 
    overflow: hidden;
   }
}

#root {
  overflow: auto;
  background-image: url('./shared_images/margin-hatching-tile.jpg');
  background-repeat: repeat;
  width: 100vw;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  transition: none !important;
  -webkit-overflow-scrolling: touch;
  // overflow-y: scroll;

  @include large { padding: 0 calc((100vw - 992px) / 2) }
  
  
  .hidden { display: none }
}

.tablet {
  display: none;
  @include medium { display: flex }
}

.mobile {
  display: flex;
  @include medium { display: none }
}
