@import '../../scss/base';
@import '../../scss/colors';

.ucsf-footer {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  box-sizing: border-box;
  height: 60px; 
  width: 100%;
  padding: 0 20px;
  margin: 0;
  background-color: $dark_gray;
  @include medium { padding: 0 40px; }

  .ucsf-image { height: 30px; }

  .user-id { color: white; }
}