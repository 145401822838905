@import '../../scss/base';
@import '../../scss/colors';
@import '../../scss/hiv';

$numOfIcons: 5;
$iconWidth: 125px;

.prevent {
  .tabs {  
    @include tab-title-bar;
    width: 100%;
    margin-bottom: 30px;

    &.tablet { min-height: 770px; }
  }

  .tabs-icon-bar {
    flex-direction: column;
    @include medium { flex-direction: row; }

    .tab-icon-container {
      display: flex;
      align-items: center;
      padding: 9px 15px;

      @include medium { padding: 0; }

      p { 
        margin: 0 0 0 15px;
        color: white;
        @include archer;
      }

      .tab-icon {
        width: 60px;
        z-index: 50;
        border-radius: 50%;
        border: 1px solid white;

        @include medium { 
          width: $iconWidth;
          border-radius: 0;
          z-index: 0;
          border: none;
        }
      }

      .down-arrow {
        width: 15px;
        margin-left: auto;
      }
    }
  }

  .selected-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0;
    @include medium { width: $iconWidth * $numOfIcons; }
    @include bullet-list-colors;

    .bullet-list { margin: 5px 15px 20px; }

    .ImageBanner {
      .text-container span {
        white-space: pre-wrap;

        @include small { white-space: normal; }
      }
      
      img {
        width: 30%;
        
        @include medium { width: auto; }

        &.PrEP { width: 30%; }
      }
    }

  }
}
