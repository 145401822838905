@import '../../scss/hiv';

.side-effects {
  @include icon-bullet-list-styling;

  .icon-bullet-list {
    li {
      margin-bottom: 1rem;

      .icon-bullet-grouping { 
        @include bullet-list-colors;
      }
    }
    
    > li { margin-bottom: 30px; }
    
  }
}