@import '../../../scss/base';

.ReactModalPortal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  overflow-y: scroll;
}
  
.overlay {
  width: 100vw;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  z-index: 150;
  min-height: 100%;
  
  .dialog {
    background-color: white;
    width: 100%;
    position: relative;
    max-width: 500px;
    border-radius: 6px;
    box-sizing: border-box;

    button.close-x {
      //make a transparent X
      background-image: url('./close-icon.png');
      background-color: transparent;
      background-size: contain;
      border: none;
      color: transparent;
      //positioning & sizing
      font-size: 0;
      position: absolute;
      right: -7px;
      top: -7px;
      padding: 15px;
    }

    .dialog-body {
      padding: 15px;
      background-color: white;
      @include medium { padding: 20px; }
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      p { 
        margin-bottom: 0;
      }

      div, p, li { 
        font-size: 1rem;

        @include medium { font-size: 1.125rem; }
      }
    }
  }
}
