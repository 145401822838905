@import '../../scss/hiv';

.pregnancy {
  .dialog-box { 
    @include dialog-box-styling;
    max-width: 400px;
    align-self: center;
    margin-bottom: 30px;
    height: 90px;
  } 
}

.pregnancy-dialog.dialog {
  li:before { color: $medium_blue }
}